import React from 'react'
import Card from '../Card/Card'
import Heading from '../Heading/Heading'

const CardComponents = () => {
    return (
        <div>
            <section className=' px-10 pb-10 content_box'>
                <Heading title="It's Easy to Get Started." desc="Ludo Best BD is Easy, Fast and Cool" />
                <div className='grid gap-6 lg:grid-cols-4   mt-10'>
                    <Card image="assets/image/features/user.png" text="Sign up and create a profile. Be crazy. Be cool" />
                    <Card image="assets/image/features/money.png" text="Add Money on Wallet and Join a Match." />
                    <Card image="assets/image/features/verify.png" text="Join the community and Be Active and Fast." />
                    {/* <Card image="assets/image/icon/one.png" text="Grow income doing what you love. Go for glory. " /> */}
                </div>

            </section>
        </div>
    )
}

export default CardComponents